import React, { useState, useEffect, MouseEvent } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { BLOCKS } from '@contentful/rich-text-types';
import InsightsWhitePaperStyled from '@/components/styled/InsightsWhitePaperStyled';
import { IContentfulWhitepaper } from '@/types';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import Helpers from '@/utils/helpers';
import { subscribe } from '@/http-request/whitepaper-request';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

// eslint-disable-next-line no-shadow
enum Form {
  SUCCESS,
  FAILED,
  NORMAL,
}

const ContentfulWhitepaper: React.FC<IContentfulWhitepaper> = ({ data }) => {
  const windowWidth = Helpers.getWindowInnerWidth();
  const laptop = windowWidth <= Helpers.window.breakpoint.desktop - 1;
  const isTablet = windowWidth <= Helpers.window.breakpoint.tablet;
  const [formState, setFormState] = useState<Form>(Form.NORMAL);

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const link = event.currentTarget.href;
    const target = event.currentTarget.target || `_self`;

    if (link.includes(`#`)) {
      const url = link.split(`#`);
      const targetElement = document.getElementById(url[1]);

      if (target) {
        event.preventDefault();
        event.stopPropagation();
        targetElement.scrollIntoView({
          behavior: `smooth`,
        });
      }
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    if (formState !== Form.SUCCESS) {
      Promise.all([subscribe(form)])
        .then((res) => {
          if (res[0]?.status === 201) {
            setFormState(Form.SUCCESS);
          } else {
            setFormState(Form.FAILED);
          }
        })
        .catch(() => {
          setFormState(Form.FAILED);
        });
    }
  };

  useEffect(() => {
    if (formState === Form.SUCCESS) {
      setTimeout(() => {
        window.open(data.contentfulWhitepaper?.file?.file?.url, `_blank`);
      }, 1000);
    }
  }, [formState]);

  const override = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: ({ content }, _children) => (
        <li>
          <h1>{content[0].content[0].value}</h1>
        </li>
      ),
    },
  };

  const showFeaturedImage = (): ImageDataLike =>
    isTablet
      ? data.contentfulWhitepaper?.mobileImage?.image?.gatsbyImageData
      : data.contentfulWhitepaper?.image?.image?.gatsbyImageData;

  const renderForm = () => (
    <>
      <div className="details">
        <div className="whitepaper-image container">
          <Reveal className="banner">
            <div className="image-container whitepaper-image-container">
              <GatsbyImage
                image={getImage(showFeaturedImage())}
                alt={data.contentfulWhitepaper?.image?.image?.title || ``}
              />
            </div>
          </Reveal>
        </div>

        <Reveal className="post-container">
          <div className="page-label-container">
            <h5 className="label">IONA WHITEPAPER</h5>
          </div>
          <div className="title-container">
            <h1>{data.contentfulWhitepaper.title}</h1>
            {data.contentfulWhitepaper?.pipedriveId && (
              <a
                href="#download-form"
                className="btn"
                onClick={handleLinkClick}
              >
                Download Now
              </a>
            )}
          </div>
          <div className="featured-remarks">
            <ContentfulRawToComponent
              raw={data.contentfulWhitepaper?.featuredRemarks?.raw}
              options={override}
            />
          </div>
        </Reveal>

        <Reveal className="post-container">
          <div className="remarks">
            {laptop && (
              <ContentfulRawToComponent
                raw={data.contentfulWhitepaper?.featuredRemarks?.raw}
                options={override}
              />
            )}
            <ContentfulRawToComponent
              raw={data.contentfulWhitepaper?.remarks?.raw}
              options={override}
            />
          </div>
        </Reveal>
      </div>
      <Reveal className="content">
        <section id="download-form" className="whitepaper-form">
          <form
            onSubmit={onSubmitHandler}
            id="form-whitepaper"
            method="post"
            data-form-type="whitepaper"
            className="container"
          >
            <fieldset>
              <div className="info-row">
                <label htmlFor="name">
                  <span>Name</span>
                  <input id="name" type="text" name="name" required />
                </label>
                <label htmlFor="email">
                  <span>Email</span>
                  <input id="email" type="email" name="email" required />
                </label>

                <label htmlFor="jobTitle">
                  <span>Job Title</span>
                  <input id="jobTitle" type="text" name="jobTitle" required />
                </label>
                <label htmlFor="company">
                  <span>Company Name</span>
                  <input id="company" type="text" name="company" required />
                </label>
              </div>

              <div className="consent-row">
                <label htmlFor="consent">
                  <span>
                    I consent to allow IONA to store and process the personal
                    information submitted above to provide the content
                    requested.
                  </span>
                  <input id="consent" type="checkbox" name="consent" required />
                </label>
                <input
                  id="wpTitle"
                  type="hidden"
                  name="wpTitle"
                  value={`${data.contentfulWhitepaper.title}`}
                />
              </div>

              <div className="policy-row">
                <p>
                  IONA can send you communications. You may unsubscribe these
                  communications at any time. For more information on how
                  unsubscribe and our privacy practices please review our
                  {` `}
                  <a href="/privacy">Privacy Policy</a>.
                </p>
              </div>
              <div className="submit-button">
                <button type="submit" className="wide send-button">
                  DOWNLOAD THE WHITEPAPER
                </button>
              </div>
            </fieldset>
          </form>
        </section>
      </Reveal>
    </>
  );

  const renderSuccessfullySubscribed = () => (
    <>
      <Reveal className="main-details">
        <div className="title-container">
          <div className="subscribed-message">
            <h2>Thank you!</h2>
          </div>
        </div>
      </Reveal>

      <Reveal className="content">
        <div className="subscribed-guide">
          <h3>Your download should have started.</h3>
        </div>
      </Reveal>
    </>
  );

  return (
    <InsightsWhitePaperStyled>
      {usePageMeta(data.contentfulWhitepaper?.meta)}
      <div className="content container single-article-page whitepaper-page">
        <Reveal className={`${laptop ? `fadeInUp` : ``}`}>
          <div className="page-title link-title">
            <Link className="link back" to="/insights">
              All insights
            </Link>
          </div>
        </Reveal>
        <Reveal>
          <div className={`${formState === Form.SUCCESS ? `subscribed` : ``}`}>
            {formState === Form.SUCCESS
              ? renderSuccessfullySubscribed()
              : renderForm()}
          </div>
        </Reveal>
      </div>
    </InsightsWhitePaperStyled>
  );
};

export const query = graphql`
  query Whitepaper($id: String) {
    contentfulWhitepaper(id: { eq: $id }) {
      title
      slug
      featuredRemarks {
        raw
      }
      remarks {
        raw
      }
      image {
        image {
          gatsbyImageData
        }
      }
      mobileImage {
        image {
          gatsbyImageData
        }
      }
      pipedriveId
      file {
        file {
          url
          contentType
          fileName
          details {
            size
          }
        }
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default ContentfulWhitepaper;
