import axios from 'axios';
import moment from 'moment';

interface ResponseData {
  status: number;
  statusText: string;
  data?: any;
}

export const subscribe = async (formData: FormData): Promise<ResponseData> => {
  const ownerId = 8175591; // Mikko Mantila

  const name = formData.get(`name`);
  const email = formData.get(`email`);
  const company = formData.get(`company`);
  const jobTitle = formData.get(`jobTitle`);

  // Org payload
  const orgPayload = {
    name: company,
    add_time: ``,
    owner_id: ownerId,
    label: ``,
    visible_to: `3`,
  };

  // Contact payload
  const contactPayload = {
    name,
    owner_id: ownerId,
    org_id: 0,
    job_title: jobTitle,
    email: [
      {
        value: email,
        primary: `true`,
        label: `main`,
      },
    ],
    phone: [],
    label: ``,
    visible_to: `3`,
    marketing_status: `subscribed`,
    add_time: ``,
  };

  const leadPayload = {
    title: `${company} Lead`,
    owner_id: ownerId,
    label_ids: [`c01ffdd0-41a7-11ee-88a5-6bc6f267f557`],
    person_id: 0,
    organization_id: 0,
    value: {
      amount: 0,
      currency: `EUR`,
    },
    expected_close_date: moment().add(1, `months`).format(`YYYY-MM-DD`),
    visible_to: `3`,
    was_seen: 0,
  };

  const instance = axios.create({
    baseURL: process.env.GATSBY_PIPEDRIVE_URL,
    headers: {
      'Content-Type': `application/json`,
    },
    params: {
      api_token: process.env.GATSBY_PIPEDRIVE_TOKEN,
    },
  });

  // Send requests and associate
  const finalResponse = await instance
    .post(`/organizations`, JSON.stringify(orgPayload))
    .then(async (response) => {
      contactPayload.org_id = response.data.data.id;

      return instance.post(`/persons`, JSON.stringify(contactPayload));
    })
    .then(async (response) => {
      leadPayload.person_id = response.data.data.id;
      leadPayload.organization_id = response.data.data.org_id.value;

      return instance.post(`/leads`, JSON.stringify(leadPayload));
    })
    .then((response) => response);

  return finalResponse;
};
